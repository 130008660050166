import React from "react";
import styles from "./Landing.module.css";

const Landing = () => {
  return (
    <div className={styles.header}>
      <header className={styles.header_content}>
        <h1>
          Announcement: <span>Embracing a New Era: Transition from </span>
          <br />
          EraSwap Blockchain to Proof-of-Gods NFT Collection
        </h1>
        <img src="/assets/Logo.png" className={styles.logo} alt="" />
      </header>
      <hr />
      <div className={styles.content}>
        <section className={styles.main_section}>
          <div className={styles.content_left}>
            <h1>DEAR ERASWAP COMMUNITY,</h1>
            <nav className={styles.text}>
              We are excited to announce a transformative new chapter for our
              community. While the EraSwap Blockchain Network will be
              discontinued, we are embarking on an inspiring journey that
              leverages the burgeoning potential of NFTs. Despite our efforts
              and innovations, including offering 4.8 crore free blockchain
              transactions, zero transaction fees, and 18 middlemen-free
              platforms, EraSwap has not reached the anticipated traction. The
              network now requires significant investment to upgrade, which
              cannot be sustained without adequate community and developer
              support. <br />
              <br /> In light of these challenges, we are thrilled to introduce
              shift from Fungible Tokens to Non Fungible Tokens ( NFT) where 1
              NFT will be airdropped for every 48069 EraSwap holding on EraSwap
              Network. For this we dont need a separate ecosystem or Blockchain
              Network or Large team of developers, Expensive upgrade , Teething
              recurring maintaince With this shift community will be connected
              to bigger Ecosystem on Decentralized Marker place Opensea.io and
              Polygon network Blockchain for minimal user dependent charges
              .These NFT will be Airdropped to every holder of EraSwap. <br />{" "}
              <br />
              Fungible tokens are identical, interchangeable, and have the same
              value, while non- fungible tokens (NFTs) are unique and
              one-of-a-kind.
              <br /> <br />
              Welcome to "Proof-of-Gods" NFT collection. All assets on the
              EraSwap Network will receive an airdrop from this unique
              collection, consisting of 49,048 divine photographs. Your
              allocation will be proportional to your holdings in the EraSwap
              network. For example, if you held 1% of the circulating supply of
              EraSwap, you will receive 1% of the total NFTs in the
              Proof-of-Gods collection
            </nav>
          </div>
          <div className={styles.content_right}>
            <div className={styles.image_container}>
              <img src="/assets/banner.png" alt="" />
            </div>
            <div className={styles.button_group}>
              <a href="#" className={styles.PROOF}>
                GO TO PROOF OF GODS
              </a>
              <a
                href="https://opensea.io/collection/proof-of-gods-unleashed"
                target="_blank"
                className={styles.NFT}
              >
                GO TO PROOF OF GOD NFT COLLECTION
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Landing;
