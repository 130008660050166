import React from "react";
import styles from "./Third.module.css";

const Third = () => {
  return (
    <div className={styles.top1}>
      <div className={styles.top1_contents}>
        <img src="/assets/Logo.png" className={styles.logo} alt="" />
      </div>
      <div className={styles.mid_section}>
        <h2>
          Incase you did not receive EraSwap NFT inspite of Holding EraSwap on
          network then please write to{" "}
          <a href="#" className={styles.a_tag}>
            info@proofofgods.com
          </a>
        </h2>
        <h1>WITH YOUR</h1>
      </div>
      <div className={styles.contact_us}>
        <div className={styles.Left_cont}>
          <h3>{`1) wallet address`}</h3>
          <h3>{`2) Your full name`}</h3>
          <h3>{`3) ID proof`}</h3>
          <h3>{`4) Token type and balances`}</h3>

          <div className={styles.icons}>
            <a target="_blank" href="https://www.facebook.com/proofofgods">
              <img src="/assets/facebook.png" alt="" />
            </a>
            <a target="_blank" href="https://x.com/proofofgods">
              <img src="/assets/twitter.png" alt="" />
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/97920389/admin/feed/posts/">
              <img src="/assets/linkedin.png" alt="" />
            </a>
            <a target="_blank" href="https://www.instagram.com/proofofgods/">
              <img src="/assets/instagram.png" alt="" />
            </a>
            <a target="_blank" href="https://in.pinterest.com/proofofgods/">
              <img src="/assets/pinterest.png" alt="" />
            </a>
            <a target="_blank" href="https://www.reddit.com/user/ProofofGods/">
              <img src="/assets/reddit.png" alt="" />
            </a>
            <a target="_blank" href="https://medium.com/@proofofgods">
              <img src="/assets/medium.png" alt="" />
            </a>
            <a target="_blank" href="https://www.quora.com/profile/Proof-Of-Gods">
              <img src="/assets/quora.png" alt="" />
            </a>
          </div>
        </div>
        {/* <div className={styles.Right_cont}>
          <img src="/assets/side.png" alt="" className={styles.side} />
        </div> */}
      </div>
      <div className={styles.btn_group}>
        <a href="https://opensea.io/collection/proof-of-gods-unleashed" target="_blank" className={styles.PROOF}>Steps to List Your POG NFT</a>
        <a href="https://opensea.io/collection/proof-of-gods-unleashed" target="_blank" className={styles.NFT}>
          Video Steps to List Your POG NFT
        </a>
      </div>
    </div>
  );
};

export default Third;
